import PropTypes from 'prop-types'

import Divider from 'components/Divider'
import Spacer from 'components/Spacer'
import TeamMember from 'components/TeamMember'

import { Child, Inner, Root } from './TeamSlice.style'

const TeamSlice = ({ team }) => {
  return (
    <>
      <Divider />
      <Spacer y={8} />
      <Root>
        <Inner>
          {team.map((teamMember, index) => (
            <Child key={index}>
              <TeamMember {...teamMember} />
            </Child>
          ))}
        </Inner>
      </Root>
      <Spacer y={8} />
      <Divider />
    </>
  )
}

TeamSlice.propTypes = {
  team: PropTypes.array,
}

TeamSlice.defaultProps = {}

export default TeamSlice
