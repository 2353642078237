import PropTypes from 'prop-types'

import ArticleCard from 'components/ArticleCard'

import { Root } from './ArticleSlice.style'

const ArticleSlice = ({ article }) => {
  return (
    <Root>
      <ArticleCard {...article} />
    </Root>
  )
}

ArticleSlice.propTypes = {
  article: PropTypes.object,
}

ArticleSlice.defaultProps = {}

export default ArticleSlice
