import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

export const Main = styled.div`
  ${tw`grid items-end gap-8`};

  @media (min-width: 48rem) {
    grid-template-columns: repeat(2, auto);
  }
`

export const Columns = styled.div`
  /* ${tw`grid grid-cols-1 gap-8`}; */
  ${tw`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8`};
`

export const Column = styled.div``
