import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import ArticleCard from 'components/ArticleCard'
import Link from 'components/Link'
import ProductCardSmall from 'components/ProductCardSmall'
import Spacer from 'components/Spacer'

import { Article, Image, ImageWrapper, Main, Product, Root } from './ArticleAndProductSlice.style'

const ArticleAndProductSlice = ({ image, article, product }) => {
  return (
    <Root>
      <ImageWrapper>
        <Image src={image} alt="" />
      </ImageWrapper>
      <Main>
        <Article>
          <ArticleCard {...article} />
          <Spacer y={4} />
          <Link text="Read more" href={`/journal/${article.handle}`} />
        </Article>
        {isPresent(product) && (
          <Product>
            <ProductCardSmall {...product} />
          </Product>
        )}
      </Main>
    </Root>
  )
}

ArticleAndProductSlice.propTypes = {
  image: PropTypes.string,
  article: PropTypes.object,
  product: PropTypes.object,
}

ArticleAndProductSlice.defaultProps = {}

export default ArticleAndProductSlice
