import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Main, Root } from './CollectionCard.style'

const baseUrl = '/collections'

const loader = ({ src, width, quality }) => {
  return `${src}?w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
    .replace('s3.amazonaws.com/armoury-storage-production', 'the-armoury-s3.imgix.net')
    .replace('armoury-collections-cms.s3.amazonaws.com', 'the-armoury-s3.imgix.net')
}

const CollectionCard = ({ title, description, handle, image, reverse, theme }) => {
  return (
    <Link href={`${baseUrl}/${handle}`}>
      <Root $reverse={reverse} $theme={theme}>
        <Image
          loader={loader}
          src={image}
          alt={title}
          width={600}
          height={400}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
        <Main $reverse={reverse} $theme={theme}>
          <Header size="sm">{title}</Header>
          <Spacer y={8} />
          <Text>{description}</Text>
          <Spacer y={8} />
          <Button text="Shop collection" variant="outlined" />
        </Main>
      </Root>
    </Link>
  )
}

CollectionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handle: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
}

CollectionCard.defaultProps = {
  reverse: false,
}

export default CollectionCard
