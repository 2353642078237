import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

import { isBlank } from 'utils'

const backgroundImage = ({ $backgroundImageUrl }) => {
  if (isBlank($backgroundImageUrl)) return null

  return {
    backgroundImage: `url(${$backgroundImageUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
}

const padding = ({ $align }) => {
  if ($align === 'left') return { paddingRight: '50%' }
  if ($align === 'right') return { paddingLeft: '50%' }

  return null
}

export const Root = styled.div`
  ${tw`flex flex-col items-center justify-center py-insetY px-insetX`};
  min-height: ${rem(308)};
  ${padding};
  ${backgroundImage};
  text-align: center;
  color: var(--color-white);
`

export const Content = styled.div`
  ${padding};
`
