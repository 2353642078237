import PropTypes from 'prop-types'

import Header from 'components/Header'
import NewsletterSubscribeForm from 'components/NewsletterSubscribeForm'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Form, HeaderAndBody, Main, Root } from './CampaignMonitorSlice.style'

const CampaignMonitorSlice = ({ header, body, successMessage }) => {
  return (
    <Root>
      <Main>
        <HeaderAndBody>
          <Header size="sm" balance>
            {header}
          </Header>
          <Spacer y={3} />
          <Text html={body} prose />
        </HeaderAndBody>
        <Form>
          <NewsletterSubscribeForm successMessage={successMessage} />
        </Form>
      </Main>
    </Root>
  )
}

CampaignMonitorSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  successMessage: PropTypes.string,
}

CampaignMonitorSlice.defaultProps = {}

export default CampaignMonitorSlice
