import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Accordion from 'components/Accordion'

import { Root } from './AccordionSlice.style'

const AccordionSlice = ({ items }) => {
  if (isBlank(items)) return null

  return (
    <Root>
      <Accordion items={items} />
    </Root>
  )
}

AccordionSlice.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      body: PropTypes.string,
      buttonText: PropTypes.string,
      buttonLink: PropTypes.string,
    })
  ),
}

AccordionSlice.defaultProps = {}

export default AccordionSlice
