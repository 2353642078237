// import PropTypes from 'prop-types'
import { isPresent } from 'utils'

import Button from 'components/Button'
import CollectionCard from 'components/CollectionCard'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Collections, Masthead, Root } from './CollectionsSlice.style'

const CollectionsSlice = ({ header, body, buttonLink, buttonText, collections, theme }) => {
  return (
    <Root $theme={theme}>
      {/* <div>CollectionsSlice</div> */}
      <Masthead>
        <Header balance>{header}</Header>
        {isPresent(body) && (
          <>
            <Spacer y={4} />
            <Text html={body} />
          </>
        )}
        {isPresent(buttonText) && isPresent(buttonLink) && (
          <Button variant="inline" text={buttonText} as="a" href={buttonLink} />
        )}
      </Masthead>
      <Collections>
        {collections.filter(Boolean).map(({ id, ...props }, index) => (
          <div key={id}>
            <CollectionCard {...props} reverse={index % 2 === 0} theme={theme} />
          </div>
        ))}
      </Collections>
    </Root>
  )
}

CollectionsSlice.propTypes = {}

CollectionsSlice.defaultProps = {}

export default CollectionsSlice
