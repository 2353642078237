import PropTypes from 'prop-types'

import isBlank from 'utils/isBlank'

import ArticleCard from 'components/ArticleCard'
import Divider from 'components/Divider'
import Spacer from 'components/Spacer'

import { Column, Columns, Root } from './ReadMoreSlice.style'

const ReadMoreSlice = ({ showDivider, articles }) => {
  if (isBlank(articles)) return null

  return (
    <Root>
      <Divider text={showDivider ? 'Read more' : null} />
      <Spacer y={8} />
      <Columns $articlesLength={articles.length}>
        {articles.map((article, index) => (
          <Column key={index}>
            <ArticleCard {...article} layout="horizontal" />
          </Column>
        ))}
      </Columns>
      <Spacer y={8} />
      <Divider />
    </Root>
  )
}

ReadMoreSlice.propTypes = {
  showDivider: PropTypes.bool,
  articles: PropTypes.array,
}

ReadMoreSlice.defaultProps = {
  showDivider: true,
}

export default ReadMoreSlice
