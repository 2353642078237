import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { without } from 'ramda'
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Link from 'components/Link'
import Text from 'components/Text'

import { Body, BodyWrapper, Caret, CaretIcon, HeaderButton, HeaderWrapper, LinkWrapper, Root } from './Accordion.style'
import CaretSvg from './svg/caret.svg'

const Accordion = forwardRef(({ items }, ref) => {
  const [parsedItems, setParsedItems] = useState([])

  useImperativeHandle(ref, () => ({
    toggleItemAtIndex: (index) => {
      const id = parsedItems[index].id
      toggleItem(id)
    },
  }))

  useEffect(() => {
    setParsedItems(items.map((item) => ({ ...item, id: nanoid() })))
  }, [items])

  const [expandedItems, setExpandedItems] = useState([])

  const toggleItem = (id) => {
    if (itemIsExpanded(id)) return setExpandedItems((prev) => without([id], prev))

    setExpandedItems((prev) => [...prev, id])
  }

  const itemIsExpanded = (id) => expandedItems.includes(id)

  return (
    <Root>
      {parsedItems.map(({ id, header, body, linkText, linkHref }) => (
        <Fragment key={id}>
          <HeaderWrapper>
            <Header size="sm">
              <HeaderButton
                onClick={() => toggleItem(id)}
                type="button"
                aria-expanded={itemIsExpanded(id)}
                aria-controls={id}
                id={`${id}-label`}
              >
                {header}
                <Caret $isOpen={itemIsExpanded(id)}>
                  <CaretIcon $isOpen={itemIsExpanded(id)}>
                    <CaretSvg width={12} height={7} />
                  </CaretIcon>
                </Caret>
              </HeaderButton>
            </Header>
          </HeaderWrapper>
          <BodyWrapper
            duration={300}
            height={itemIsExpanded(id) ? 'auto' : 0}
            id={id}
            role="region"
            aria-labelledby={`${id}-label`}
          >
            <Body>
              <Text html={body} />
              {isPresent(linkText) && isPresent(linkHref) && (
                <LinkWrapper>
                  <Link text={linkText} href={linkHref} />
                </LinkWrapper>
              )}
            </Body>
          </BodyWrapper>
        </Fragment>
      ))}
    </Root>
  )
})

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      body: PropTypes.string,
      linkText: PropTypes.string,
      linkHref: PropTypes.string,
    })
  ),
}

Accordion.defaultProps = {}

Accordion.displayName = 'Accordion'

export default Accordion
