import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import ArticleCard from 'components/ArticleCard'
import Header from 'components/Header'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'

import { Column, Columns, Root } from './ArticlesSlice.style'

const ArticlesSlice = ({ header, body, articles }) => {
  return (
    <Root>
      {isPresent(header) && (
        <>
          <Header>{header}</Header>
          <Spacer y={8} />
        </>
      )}
      {isPresent(body) && (
        <>
          <RichText html={body} prose />
          <Spacer y={8} />
        </>
      )}
      <Columns $articlesLength={articles?.length || 0}>
        {articles?.map((article, index) => (
          <Column key={index}>
            <ArticleCard {...article} />
          </Column>
        ))}
      </Columns>
    </Root>
  )
}

ArticlesSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  articles: PropTypes.array,
}

ArticlesSlice.defaultProps = {}

export default ArticlesSlice
