import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid md:grid-cols-2 gap-x-16 gap-y-8 px-insetX ta-max-width`};
  color: var(--color-gold-black);
`

export const Introduction = styled.div``

export const Main = styled.div``
