import Link from 'next/link'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { isPresent } from 'utils'

import Header from 'components/Header'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'

import { Excerpt, Image, Main, Root } from './ArticleCard.style'

const baseUrl = '/journal/'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const ArticleCard = ({ layout, handle, imageUrl, category, title, excerpt }) => {
  const WrapperComponent = isPresent(handle) ? Link : Fragment
  const href = isPresent(handle) ? `${baseUrl}${handle}` : undefined

  return (
    <WrapperComponent href={href} passHref legacyBehavior>
      <Root $layout={layout} as={isPresent(href) ? 'a' : 'div'}>
        {isPresent(imageUrl) && <Image loader={loader} width="600" height="400" src={imageUrl} alt="" />}
        <Main>
          {isPresent(category) && (
            <>
              <SubHeader>{category}</SubHeader>
              <Spacer y={1} />
            </>
          )}
          <Header size="sm">{title}</Header>
          <Spacer y={2} />
          <Excerpt>
            <RichText html={excerpt} />
          </Excerpt>
        </Main>
      </Root>
    </WrapperComponent>
  )
}

ArticleCard.propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
}

ArticleCard.defaultProps = {
  layout: 'vertical',
}

export default ArticleCard
