import { isPresent, prismicAsHtml } from 'utils'

const prismicArticleToArticleCardProps = ({ uid, slug, data }) => {
  // NOTE: slug is required for converting Algolia results
  // NOTE: Algolia results do not have category data
  return {
    layout: 'vertical',
    handle: isPresent(uid) ? uid : slug,
    imageUrl: data.cover_image?.url,
    category: data.category?.data?.title || null,
    title: data.title,
    excerpt: prismicAsHtml(data.summary),
  }
}

export default prismicArticleToArticleCardProps
