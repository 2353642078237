import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { isPresent } from 'utils'

import FormattedDateRange from 'components/FormattedDateRange'
import Header from 'components/Header'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { ImageWrapper, LocationBadgeX, LocationBadgeY, Root, Title } from './TrunkShowCard.style'
import HKx from './svg/hk-x.svg'
import HKy from './svg/hk-y.svg'
import NYx from './svg/ny-x.svg'
import NYy from './svg/ny-y.svg'
import SHx from './svg/sh-x.svg'
import SHy from './svg/sh-y.svg'

const baseUrl = '/custom-bespoke/trunk-shows/'

const locationBadges = {
  HK: [HKx, HKy],
  SH: [SHx, SHy],
  NY: [NYx, NYy],
}

const TrunkShowCard = ({ handle, imageUrl, locationBadge, title, location, startDate, endDate, summary }) => {
  const [badgeX, badgeY] = locationBadges[locationBadge] || []

  const WrapperComponent = isPresent(handle) ? Link : Fragment
  const href = isPresent(handle) ? `${baseUrl}${handle}` : undefined

  return (
    <WrapperComponent href={href} passHref legacyBehavior>
      <Root as={isPresent(href) ? 'a' : 'div'}>
        <ImageWrapper>
          <Image width={400} height={300} style={{ width: '100%', height: 'auto' }} src={imageUrl} alt="" />
          {badgeX && badgeY && (
            <>
              <LocationBadgeX>{badgeX({ viewBox: '0 0 437.6 249.5' })}</LocationBadgeX>
              <LocationBadgeY>{badgeY({ viewBox: '0 0 249.5 437.6' })}</LocationBadgeY>
            </>
          )}
        </ImageWrapper>
        <Spacer y={4} />
        <Title>
          <Header size="sm">{title}</Header>
        </Title>
        <Spacer y={2} />
        <Text>{location}</Text>
        <Text>
          <FormattedDateRange start={startDate} end={endDate} />
        </Text>
        <Spacer y={2} />
        <RichText html={summary} />
      </Root>
    </WrapperComponent>
  )
}

TrunkShowCard.propTypes = {
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  locationBadge: PropTypes.oneOf(['HK', 'NY', 'SH']),
  title: PropTypes.string,
  location: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  summary: PropTypes.string,
}

TrunkShowCard.defaultProps = {}

export default TrunkShowCard
