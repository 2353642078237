import NextImage from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { map, prop } from 'ramda'
import { Fragment } from 'react'

import { formatMoney, formatProductSize, isBlank, isPresent } from 'utils'
import sortProductSizes from 'utils/sortProductSizes'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import {
  AltImage,
  BlurUp,
  CompareAtPrice,
  EssentialTag,
  ImageContainer,
  ImageWrapper,
  Main,
  Root,
  SizesList,
  SizesListItem,
} from './ProductCard.style'

const blurUp = (src) => {
  return `${src}&w=100&blur=600&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-shopify.imgix.net'
  )
}

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&auto=format,compress`.replace(
    'cdn.shopify.com/s/files',
    'the-armoury-shopify.imgix.net'
  )
}

const imageSizes =
  '(min-width: 100rem) 26rem, (min-width: 48rem) calc((100vw - 16rem) / 3), (min-width: 36rem) 50vw, 100vw'

const ProductCard = ({
  handle,
  variantId,
  imageUrl,
  altImageUrl,
  artisan,
  title,
  price,
  compareAtPrice,
  sizes,
  isEssential,
  baseUrl = '/products/',
}) => {
  if (isBlank(title)) return null

  const PossibleLink = isPresent(handle) ? Link : Fragment

  let href = null

  if (isPresent(handle) && isPresent(variantId)) {
    href = `${baseUrl}${handle}?variant=${variantId}`
  } else if (isPresent(handle)) {
    href = `${baseUrl}${handle}`
  }

  const sortedSizeValues = sortProductSizes(map(prop('size'), sizes))
  const sortedSizes = sizes.sort(
    ({ size: sizeA }, { size: sizeB }) => sortedSizeValues.indexOf(sizeA) - sortedSizeValues.indexOf(sizeB)
  )

  return (
    <Root>
      {isEssential && (
        <EssentialTag>
          <Text size="xs" font="proxima" tracking="wide" transform="uppercase">
            Essential
          </Text>
        </EssentialTag>
      )}
      <ImageWrapper>
        {isPresent(imageUrl) && (
          <PossibleLink href={href} passHref>
            <ImageContainer>
              <BlurUp src={blurUp(imageUrl)} alt="" />
              <NextImage src={imageUrl} width={200} height={200} alt="" sizes={imageSizes} loader={loader} />
            </ImageContainer>
          </PossibleLink>
        )}
        {isPresent(altImageUrl) && (
          <AltImage>
            <NextImage src={altImageUrl} width={200} height={200} alt="" sizes={imageSizes} loader={loader} />
          </AltImage>
        )}
        {isPresent(sizes) && sizes?.length > 1 && (
          <SizesList>
            {sortedSizes.map(({ size, available }) => (
              <SizesListItem key={size} $available={available}>
                <Link href={href} passHref>
                  <Text>{formatProductSize(size)}</Text>
                </Link>
              </SizesListItem>
            ))}
          </SizesList>
        )}
      </ImageWrapper>

      <PossibleLink href={href} passHref>
        <Main>
          <Spacer y={4} />
          <SubHeader>{artisan}</SubHeader>
          <Spacer y={2} />
          <Header size="sm">{title}</Header>
          <Spacer y={3} />
          {isPresent(compareAtPrice) && (
            <CompareAtPrice>
              <Text font="stint">
                <s>{formatMoney(compareAtPrice, { fractional: true })}</s>
              </Text>
            </CompareAtPrice>
          )}
          <Text font="stint" size="lg">
            {formatMoney(price, { fractional: true })}
          </Text>
        </Main>
      </PossibleLink>
    </Root>
  )
}

ProductCard.propTypes = {
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  altImageUrl: PropTypes.string,
  artisan: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  compareAtPrice: PropTypes.number,
  sizes: PropTypes.arrayOf(PropTypes.shape({ size: PropTypes.any, available: PropTypes.bool })),
}

ProductCard.defaultProps = {
  sizes: [],
}

export default ProductCard
