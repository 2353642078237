import { isPresent } from 'utils'
import prismicAsHtml from 'utils/prismicAsHtml'
import prismicAsLink from 'utils/prismicAsLink'

import articleAndProductProductToProductCardSmallProps from 'converters/articleAndProductProductToProductCardSmallProps'
// import CollectionSlice from 'components/CollectionSlice'
import collectionProductToProductCardProps from 'converters/collectionProductToProductCardProps'
import prismicArticleToArticleCardProps from 'converters/prismicArticleToArticleCardProps'
import prismicTrunkShowToTrunkShowCardProps from 'converters/prismicTrunkShowToTrunkShowCardProps'

import AccordionSlice from 'components/AccordionSlice'
import ArticleAndProductSlice from 'components/ArticleAndProductSlice'
import ArticleSlice from 'components/ArticleSlice'
import ArticlesSlice from 'components/ArticlesSlice'
import BannerSlice from 'components/BannerSlice'
import ButtonSlice from 'components/ButtonSlice'
import CampaignMonitorSlice from 'components/CampaignMonitorSlice'
import CollectionsSlice from 'components/CollectionsSlice'
import ColumnsSlice from 'components/ColumnsSlice'
import DividerSlice from 'components/DividerSlice'
import FaqsSlice from 'components/FaqsSlice'
import FeaturedArticleSlice from 'components/FeaturedArticleSlice'
import GallerySlice from 'components/GallerySlice'
import HeaderSlice from 'components/HeaderSlice'
import ImageAndTextSlice from 'components/ImageAndTextSlice'
import ImageSlice from 'components/ImageSlice'
import ImagesSlice from 'components/ImagesSlice'
import IntroductionSlice from 'components/IntroductionSlice'
import NarrativeSlice from 'components/NarrativeSlice'
import PageHeaderSlice from 'components/PageHeaderSlice'
import PopularArticlesSlice from 'components/PopularArticlesSlice'
import ProductCard from 'components/ProductCard'
import ProductCardSmall from 'components/ProductCardSmall'
import ProductsSlice from 'components/ProductsSlice'
import QuoteSlice from 'components/QuoteSlice'
import ReadMoreSlice from 'components/ReadMoreSlice'
import RichTextSlice from 'components/RichTextSlice'
import TeamSlice from 'components/TeamSlice'
import TrunkShowsSlice from 'components/TrunkShowsSlice'
import VideoSlice from 'components/VideoSlice'

const sliceComponents = {
  read_more: ReadMoreSlice,
  introduction: IntroductionSlice,
  accordion: AccordionSlice,
  quote: QuoteSlice,
  video: VideoSlice,
  faqs: FaqsSlice,
  columns: ColumnsSlice,
  header: HeaderSlice,
  page_header: PageHeaderSlice,
  button: ButtonSlice,
  divider: DividerSlice,
  text: RichTextSlice,
  featured_article: FeaturedArticleSlice,
  article: ArticleSlice,
  products: ProductsSlice,
  campaign_monitor: CampaignMonitorSlice,
  articles: ArticlesSlice,
  banner: BannerSlice,
  article_and_product: ArticleAndProductSlice,
  trunk_shows: TrunkShowsSlice,
  popular_articles: PopularArticlesSlice,
  // collections: CollectionsSlice,
  // collection: CollectionSlice,
  image: ImageSlice,
  image_and_text: ImageAndTextSlice,
  narrative: NarrativeSlice,
  team: TeamSlice,
  collections: CollectionsSlice,
  images: ImagesSlice,
  gallery: GallerySlice,
}

const sliceSchemaConverters = []

sliceSchemaConverters.push({
  type: 'default',
  converter: ({ slice_type, ...rest }) => {
    console.error(`No converter found for slice: ${slice_type}`, rest)

    return {}
  },
})

sliceSchemaConverters.push({
  type: 'read_more',
  converter: ({ items, primary }) => ({
    articles: items.map((item) => {
      const {
        article,
        article: { data },
      } = item

      return {
        layout: 'vertical',
        handle: article.slug,
        imageUrl: data.cover_image?.url,
        category: data.category.data.title,
        title: data.title,
        excerpt: prismicAsHtml(data.summary),
      }
    }),
    showDivider: primary.show_read_more_header === 'Yes',
  }),
})

sliceSchemaConverters.push({
  type: 'introduction',
  converter: ({ primary }) => ({
    header: prismicAsHtml(primary.introduction),
    body: prismicAsHtml(primary.body1),
  }),
})

// button_link is a Link e.g. { link_type, url }
sliceSchemaConverters.push({
  type: 'accordion',
  converter: ({ items }) => ({
    items: items.map(({ header, body1, button_text, button_link }) => ({
      header: header,
      body: prismicAsHtml(body1),
      buttonText: button_text,
      buttonLink: prismicAsLink(button_link),
    })),
  }),
})

sliceSchemaConverters.push({
  type: 'video',
  converter: ({ primary }) => ({
    videoUrl: primary.youtube_video_url,
    title: primary.title1,
  }),
})

sliceSchemaConverters.push({
  type: 'header',
  converter: ({ primary }) => {
    const variants = {
      Title: 'header',
      'Sub-title': 'subheader',
    }

    const layouts = {
      'Inset with Background': 'inset',
    }

    return {
      text: prismicAsHtml(primary.header),
      variant: variants[primary.style],
      layout: layouts[primary.design] || 'default',
      align: primary.alignment?.toLowerCase(),
    }
  },
})

sliceSchemaConverters.push({
  type: 'quote',
  converter: ({ primary }) => ({
    header: primary.header,
    image: {
      url: primary.image?.url,
      alt: primary.image?.alt,
      dimensions: primary.image?.dimensions,
    },
    quote: prismicAsHtml(primary.quote),
    caption: primary.caption,
  }),
})

sliceSchemaConverters.push({
  type: 'faqs',
  converter: ({ primary, items }) => {
    try {
      const actualItems = items.map(({ faq_section: { data } }) => {
        const qAndAs = data.body[0].items
        const body = qAndAs
          .map(({ answer, question }) => {
            return [`<p class="font-bold mb-2">${question}</p>`, prismicAsHtml(answer)].join('')
          })
          .join('')
        return { header: data.title, body }
      })

      return {
        header: primary.header,
        items: actualItems,
      }
    } catch (e) {
      return null
    }
  },
})

sliceSchemaConverters.push({
  type: 'page_header',
  converter: ({ primary }) => ({
    header: primary.header,
    body: prismicAsHtml(primary.body1),
    buttonLink: prismicAsLink(primary.button_link),
    buttonText: primary.button_text,
    imageUrl: primary.image?.url,
    imageWidth: primary.image_width,
  }),
})

sliceSchemaConverters.push({
  type: 'button',
  converter: ({ primary }) => {
    const variants = {
      Default: 'default',
      Outlined: 'outlined',
      Text: 'inline',
    }
    return {
      text: primary.text,
      url: prismicAsLink(primary.link),
      variant: variants[primary.style],
    }
  },
})

sliceSchemaConverters.push({
  type: 'columns',
  converter: ({ primary, items }) => {
    const imageAlignments = {
      'Full-width': 'justify',
      'Left-aligned': 'left',
    }

    return {
      header: primary.header,
      body: prismicAsHtml(primary.body2),
      focusFirst: primary.focus_first === 'Yes',
      imageAlignment: imageAlignments[primary.images],
      maxColumnCount: parseInt(primary.maximum_column_count, 10) || 3,
      columns: items.map(({ image, header, body1, link, link_text }) => ({
        imageUrl: image?.url,
        header: header,
        body: prismicAsHtml(body1),
        linkText: link_text,
        linkUrl: prismicAsLink(link),
      })),
    }
  },
})

// TODO We seem to have lots of these
// prismicCustomTypes.json:2124
// prismicCustomTypes.json:4431
// prismicCustomTypes.json:5310
// prismicCustomTypes.json:6490
sliceSchemaConverters.push({
  type: 'divider',
  converter: ({ primary }) => {
    const variants = {
      Default: 'subheader',
      'Big Title': 'header',
      'Default Title': 'header',
      'Small Title': 'header',
    }
    const textProps = {
      Default: {},
      'Big Title': { size: 'lg' },
      'Default Title': {},
      'Small Title': { size: 'sm' },
    }

    return {
      text: primary.text || primary.header,
      variant: variants[primary.style],
      textProps: textProps[primary.style],
    }
  },
})

sliceSchemaConverters.push({
  type: 'text',
  converter: ({ primary }) => ({
    html: prismicAsHtml(primary.text),
  }),
})

sliceSchemaConverters.push({
  type: 'article',
  converter: ({ primary }) => {
    return {
      article: {
        layout: 'horizontal',
        handle: primary.article.slug,
        imageUrl: primary.article.data.cover_image?.url,
        category: primary.article.data.category.data.title,
        title: primary.article.data.title,
        excerpt: prismicAsHtml(primary.article.data.summary),
      },
    }
  },
})

sliceSchemaConverters.push({
  type: 'featured_article',
  converter: ({ primary }) => {
    // TODO: Subdued??
    const variants = {
      Default: 'default',
      'Floating Image': 'floating-image',
    }

    if (primary.article.isBroken) return null

    const article = primary.article.data || {}

    return {
      variant: variants[primary.style],
      image: article.cover_image?.url,
      category: article.category?.data?.title,
      title: article.title,
      excerpt: prismicAsHtml(article.summary),
      buttonText: primary.button_text || 'Read More',
      handle: primary.article.uid,
    }
  },
})

sliceSchemaConverters.push({
  type: 'products',
  converter: ({ primary, items }) => {
    const ProductComponent = {
      Compact: ProductCardSmall,
      Full: ProductCard,
    }[primary.product_card_style]

    return {
      header: primary.header,
      body: prismicAsHtml(primary.products_body),
      products: items
        .map(({ product }) => product)
        .filter(Boolean) // Discard dead links
        .map(collectionProductToProductCardProps),
      ProductComponent,
    }
  },
})

sliceSchemaConverters.push({
  type: 'campaign_monitor',
  converter: ({ primary }) => {
    return {
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      // Deprecated
      // campaignMonitorFormEmbedHtml: primary.campaign_monitor_form_embed_html,
      successMessage: prismicAsHtml(primary.success_message),
    }
  },
})

sliceSchemaConverters.push({
  type: 'articles',
  converter: ({ primary, items }) => {
    return {
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      articles: items.map(({ article }) => article).map(prismicArticleToArticleCardProps),
    }
  },
})

sliceSchemaConverters.push({
  type: 'banner',
  converter: ({ primary }) => {
    const buttonVariants = {
      Outlined: 'outlined',
      Solid: 'defaullt',
    }

    const contentAlignments = {
      Center: 'center',
      Left: 'left',
      Right: 'right',
    }

    return {
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      backgroundImageUrl: primary.background_image?.url,
      buttonText: primary.button_text,
      buttonLink: prismicAsLink(primary.button_link),
      buttonVariant: buttonVariants[primary.button_style],
      align: contentAlignments[primary.content_alignment],
    }
  },
})

sliceSchemaConverters.push({
  type: 'article_and_product',
  converter: ({ primary }) => {
    const {
      article,
      article: { data },
    } = primary

    return {
      image: primary.lifestyle_image?.url,
      article: {
        layout: 'vertical',
        handle: article.slug,
        imageUrl: data?.cover_image?.url,
        category: data?.category.data.title,
        title: data?.title,
        excerpt: prismicAsHtml(data?.summary),
      },
      product: primary?.product ? articleAndProductProductToProductCardSmallProps(primary.product) : null,
    }
  },
})

sliceSchemaConverters.push({
  type: 'trunk_shows',
  converter: ({ primary, items }) => {
    let trunkShows = []
    let method

    switch (primary.trunk_shows) {
      case 'All': {
        method = 'all'
        break
      }
      case 'Manual':
        method = 'manual'
        // Prismic graphQuery
        trunkShows = items.map(({ trunk_show }) => trunk_show).map(prismicTrunkShowToTrunkShowCardProps)
        break
      case 'Match on Tag(s)':
        // Never implemented
        break
    }

    return {
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      buttonText: primary.button_text,
      buttonLink: prismicAsLink(primary.button_link),
      trunkShows,
      method,
    }
  },
})

// TODO
sliceSchemaConverters.push({
  type: 'popular_articles',
  converter: () => ({}),
})

// TODO
sliceSchemaConverters.push({
  type: 'collections',
  converter: ({ primary, items }) => {
    const themes = {
      Light: 'light',
      Dark: 'dark',
    }

    const collections = items.map(({ collection }) => collection)

    return {
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      buttonText: primary.button_text,
      buttonLink: prismicAsLink(primary.button_link),
      theme: themes[primary.color_scheme],
      collections,
    }
  },
})

// TODO
sliceSchemaConverters.push({
  type: 'collection',
  converter: (foo) => ({ ...foo }),
})

// TODO
sliceSchemaConverters.push({
  type: 'image',
  converter: ({ primary }) => {
    const widths = {
      Viewport: 'viewport',
      Page: 'page',
    }

    const crops = {
      Original: 'original',
      Square: 'square',
      'TA Landscape': 'landscape',
      'TA Portrait': 'portrait',
    }

    return {
      imageUrl: primary.image?.url,
      imageWidth: primary.image?.dimensions?.width,
      imageHeight: primary.image?.dimensions?.height,
      alt: primary.alt_text,
      caption: primary.caption,
      link: prismicAsLink(primary.link),
      linkText: primary.link_text,
      width: widths[primary.width],
      crop: crops[primary.crop],
    }
  },
})

sliceSchemaConverters.push({
  type: 'images',
  converter: ({ primary, items }) => {
    const crops = {
      Original: 'original',
      Square: 'square',
      'TA Landscape': 'landscape',
      'TA Portrait': 'portrait',
    }

    const images = items.map((item) => ({
      url: item.image?.url,
      width: item.image?.dimensions?.width,
      height: item.image?.dimensions?.height,
      alt: item.alt_text,
      caption: item.caption,
      crop: crops[item.crop],
    }))

    const layouts = {
      Columns: 'columns',
      Collage: 'collage',
      Carousel: 'carousel',
      'Side-by-Side': 'side-by-side',
    }

    const focusses = {
      'Equal focus': null,
      'Focus left': 'left',
      'Focus right': 'right',
    }

    return { images, layout: layouts[primary.layout], focus: focusses[primary['side-by-side_layout']] }
  },
})

// TODO
sliceSchemaConverters.push({
  type: 'image_and_text',
  converter: ({ primary }) => {
    const crops = {
      Original: 'original',
      Square: 'square',
      'TA Landscape': 'landscape',
      'TA Portrait': 'portrait',
    }

    const designs = {
      Default: 'default',
      'Inset with Background': 'inset_with_background',
    }

    const alignments = {
      Center: 'center',
      Left: 'left',
    }

    return {
      imageUrl: primary.image?.url,
      imageWidth: primary.image?.dimensions?.width,
      imageHeight: primary.image?.dimensions?.height,
      alt: primary.image_alt_text,
      caption: primary.caption,

      design: designs[primary.design],
      reverse: primary.layout === 'Text then Image',
      crop: crops[primary.image_crop],

      header: primary.header,
      text: prismicAsHtml(primary.text),
      align: alignments[primary.text_alignment] || 'center',
      buttonLink: prismicAsLink(primary.button_link),
      buttonText: primary.button_text,
    }
  },
})

sliceSchemaConverters.push({
  type: 'narrative',
  converter: ({ primary }) => ({
    header: primary.header,
    introduction: prismicAsHtml(primary.introduction),
    body: prismicAsHtml(primary.body1),
  }),
})

sliceSchemaConverters.push({
  type: 'team',
  converter: ({ items }) => {
    const team = items.map((item) => ({
      photoUrl: item.photo?.url,
      name: item.name,
      biography: prismicAsHtml(item.bio),
    }))

    return { team }
  },
})

sliceSchemaConverters.push({
  type: 'gallery',
  converter: ({ primary, items }) => {
    const images =
      items
        .filter(({ image }) => isPresent(image))
        .map((item) => ({
          url: item.image?.url,
          width: item.image?.dimensions?.width,
          height: item.image?.dimensions?.height,
          alt: item.alt_text,
          caption: item.caption,
          // crop: crops[item.crop],
        })) || []

    return {
      subheader: primary.subheader,
      header: primary.header,
      body: prismicAsHtml(primary.body1),
      backgroundColor: primary.background_color,
      textColor: primary.text_color,
      images,
      primaryButtonText: primary.primary_button_text,
      primaryButtonLink: prismicAsLink(primary.primary_button_link),
      secondaryButtonText: primary.secondary_button_text,
      secondaryButtonLink: prismicAsLink(primary.secondary_button_link),
    }
  },
})

export { sliceComponents, sliceSchemaConverters }
