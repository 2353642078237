import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX py-insetY ta-max-width-parent`};
  background-color: var(--color-gold-white);
  border-top: solid 1px var(--color-gold-white-line);
  border-bottom: solid 1px var(--color-gold-white-line);
`

export const Columns = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8`};
`

export const Column = styled.div``
