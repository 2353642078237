import Image from 'next/image'
import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Button from 'components/Button'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Main, Root } from './PageHeaderSlice.style'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const PageHeaderSlice = ({ header, body, buttonLink, buttonText, imageUrl, imageWidth }) => {
  return (
    <Root $imageWidth={imageWidth}>
      <Main>
        <Header size="lg" balance>
          {header}
        </Header>
        {isPresent(body) && (
          <>
            <Spacer y={4} />
            <Text html={body} prose />
          </>
        )}
        {isPresent(buttonText) && isPresent(buttonLink) && (
          <>
            <Spacer y={8} />
            <Button variant="outlined" text={buttonText} as="a" href={buttonLink} />
          </>
        )}
      </Main>
      {isPresent(imageUrl) && (
        <Image
          loader={loader}
          width="600"
          height="400"
          src={imageUrl}
          alt=""
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      )}
    </Root>
  )
}

PageHeaderSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.oneOf(['1/2', '1/3']),
}

PageHeaderSlice.defaultProps = {}

export default PageHeaderSlice
