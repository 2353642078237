import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.figure`
  ${tw`px-insetX py-insetY`};
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-dark-grey);
`

export const Quote = styled.blockquote`
  ${tw`inline-flex justify-center`};
`
