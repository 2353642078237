import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`overflow-hidden px-insetX ta-max-width`};
`

export const Inner = styled.div`
  ${tw`lg:flex flex-wrap -m-8 lg:-m-16`};
`

export const Child = styled.div`
  ${tw`lg:w-1/2 p-8 lg:p-16 lg:border-l border-b`};
  border-color: var(--color-grey);

  &:nth-last-child(-n + 2):first-child,
  &:nth-last-child(-n + 2):first-child ~ & {
    ${tw`border-b lg:border-b-0`};
  }

  &:first-child {
    ${tw`lg:border-l-0!`};
  }

  &:last-child {
    ${tw`lgmax:border-b-0!`};
  }
`
