import getYouTubeID from 'get-youtube-id'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'

import { isBlank, isPresent } from 'utils'

import Header from 'components/Header'

import { Root, Title, VideoWrapper } from './VideoSlice.style'

const opts = {
  modestbranding: 1,
  listType: 'user_uploads',
  rel: 0,
}

// TODO omplement lazy YT loading
// https://github.com/paulirish/lite-youtube-embed
// https://github.com/justinribeiro/lite-youtube
// https://github.com/Daugilas/lazyYT

const VideoSlice = ({ videoUrl, title }) => {
  if (isBlank(videoUrl)) return null

  const videoId = getYouTubeID(videoUrl)

  return (
    <Root>
      <VideoWrapper>
        <YouTube videoId={videoId} opts={opts} />
      </VideoWrapper>
      {isPresent(title) && (
        <Title>
          <Header balance>{title}</Header>
        </Title>
      )}
    </Root>
  )
}

// youtube_video_url -> videoUrl
// title1 -> title

VideoSlice.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
}

VideoSlice.defaultProps = {}

export default VideoSlice
