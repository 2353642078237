import PropTypes from 'prop-types'

import { isBlank, isPresent } from 'utils'

import Header from 'components/Header'
import Link from 'components/Link'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Column, Columns, Image, ImageWrapper, Main, Root } from './ColumnsSlice.style'

const hasLink = ({ linkText, linkUrl }) => {
  return isPresent(linkText) && isPresent(linkUrl)
}

const imageWrapperEl = (data) => {
  return hasLink(data) ? 'a' : 'div'
}

const ColumnsSlice = ({ header, body, focusFirst, imageAlignment, maxColumnCount, columns }) => {
  if (isBlank(columns)) return null

  return (
    <Root>
      {isPresent(header) && (
        <>
          <Header>{header}</Header>
          <Spacer y={8} />
        </>
      )}
      {isPresent(body) && (
        <>
          <RichText html={body} prose />
          <Spacer y={8} />
        </>
      )}
      <Columns $imageAlignment={imageAlignment} $maxColumnCount={maxColumnCount}>
        {columns.map((column, index) => (
          <Column key={index} $index={index} $focusFirst={focusFirst} $imageAlignment={imageAlignment}>
            {isPresent(column.imageUrl) && (
              <ImageWrapper as={imageWrapperEl(column)} href={column.linkUrl}>
                <Image src={column.imageUrl} alt="" />
              </ImageWrapper>
            )}
            <Main>
              {isPresent(column.header) && <Header size="sm">{column.header}</Header>}
              {isPresent(column.body) && <Text html={column.body} />}
              {hasLink(column) && <Link href={column.linkUrl}>{column.linkText}</Link>}
            </Main>
          </Column>
        ))}
      </Columns>
    </Root>
  )
}

ColumnsSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  focusFirst: PropTypes.bool,
  imageAlignment: PropTypes.oneOf(['left', 'justify']),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      header: PropTypes.string,
      body: PropTypes.string,
      linkText: PropTypes.string,
      linkLink: PropTypes.string,
    })
  ),
}

ColumnsSlice.defaultProps = {
  focusFirst: false,
  imageAlignment: 'justify',
}

export default ColumnsSlice
