import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX`};
`

// TODO Replace max-w-prose with special width!
export const Inner = styled.div`
  ${tw`mx-auto max-w-prose`};
  color: var(--color-gold-black);
`
