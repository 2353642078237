import styled from 'styled-components'
import tw from 'twin.macro'

const align = ({ $align }) => {
  switch ($align) {
    case 'center':
      return tw`text-center`
    case 'right':
      return tw`text-right`
    default:
      return tw`text-left`
  }
}

export const BaseLayout = styled.div`
  ${align};
`

export const DefaultLayout = styled(BaseLayout)`
  ${tw`px-insetX ta-max-width`};
`

export const InsetLayout = styled(BaseLayout)`
  ${tw`px-insetX py-8 md:py-16 text-center ta-max-width-parent`};
  background-color: var(--color-gold-white);
`
