import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX ta-max-width grid grid-cols-2`};

  /* @media (min-width: 768px) {
    grid-template-columns: 62% 38%;
  } */

  /* SubHeader component styling override */
  [class^='SubHeader'] {
    color: var(--color-white);
  }

  /* Link component styling override */
  [class^='Link'] {
    color: var(--color-gold-light);
  }
`

export const ImageWrapper = styled.div``

export const Image = styled.img`
  ${tw`block w-full h-full object-cover`};
`

export const Main = styled.div`
  ${tw`grid place-content-between`};
  color: var(--color-white);
  background-color: var(--color-dark-grey);
`

export const Article = styled.div`
  ${tw`p-12`};
`

export const Product = styled.div`
  ${tw`p-12`};
  background-color: var(--color-darker-grey);
`
