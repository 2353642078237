import PropTypes from 'prop-types'

import Divider from 'components/Divider'

import { Root } from './DividerSlice.style'

const DividerSlice = ({ text, textProps, variant }) => {
  return (
    <Root>
      <Divider text={text} variant={variant} textProps={textProps} />
    </Root>
  )
}

// style -> variant
//   Default -> subheader
//   Big Title -> header
//   Default Title -> header
//   Small Title -> subheader
// style -> textProps
//   Big Title -> { size: 'lg' }
//   Small Title -> { size: 'sm' }

DividerSlice.propTypes = {
  text: PropTypes.string,
  textProps: PropTypes.object,
  variant: PropTypes.oneOf(['subheader', 'header']),
}

DividerSlice.defaultProps = {}

export default DividerSlice
