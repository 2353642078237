import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid md:grid-cols-2 gap-x-16 gap-y-8 px-insetX`};
`

export const Header = styled.div`
  color: var(--color-blue-grey);
`
export const Body = styled.div`
  color: var(--color-gold-black);
`
