import PropTypes from 'prop-types'

import Text from 'components/Text'

import { Body, Header, Root } from './IntroductionSlice.style'

const IntroductionSlice = ({ header, body }) => {
  return (
    <Root>
      <Header>
        <Text size="2xl" html={header} />
      </Header>
      <Body>
        <Text html={body} />
      </Body>
    </Root>
  )
}

IntroductionSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
}

IntroductionSlice.defaultProps = {}

export default IntroductionSlice
