import PropTypes from 'prop-types'

import Button from 'components/Button'

import { Root } from './ButtonSlice.style'

const ButtonSlice = ({ text, url, variant }) => {
  return (
    <Root>
      <Button as="a" href={url} text={text} variant={variant} />
    </Root>
  )
}

// style -> variant
//   Default -> default
//   Outlined -> outlined
//   Text -> inline

ButtonSlice.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'outlined', 'inline']),
}

ButtonSlice.defaultProps = {}

export default ButtonSlice
