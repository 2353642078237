import { rem } from 'polished'
import AnimateHeight from 'react-animate-height'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`border-t pt-5`};
  border-color: var(--color-grey);
`

export const HeaderButton = styled.button`
  ${tw`w-full flex justify-between items-center pb-5`};
`

export const BodyWrapper = styled(AnimateHeight)``

export const Body = styled.div`
  ${tw`border-b py-5`};
  border-color: var(--color-grey);
`

export const HeaderWrapper = styled.div`
  ${tw`border-b`};
  border-color: var(--color-grey);

  ${BodyWrapper} + & {
    ${tw`pt-5`};
  }
`

export const LinkWrapper = styled.div`
  ${tw`pt-1`};
`

export const Caret = styled.div`
  ${tw`inline-flex items-center justify-center`};
  width: ${rem(22)};
  height: ${rem(22)};
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(6)} 0 rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s;
  background-color: ${({ $isOpen }) => ($isOpen ? 'var(--color-gold)' : 'var(--color-white)')};
`

export const CaretIcon = styled.div`
  ${tw`transition transform rotate-180`};
  ${({ $isOpen }) => $isOpen && tw`rotate-0`}
  fill: ${({ $isOpen }) => ($isOpen ? 'var(--color-white)' : 'var(--color-gold)')}
`
