import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX pt-16 pb-24 ta-max-width-parent`};
  ${({ $theme }) => $theme === 'light' && { backgroundColor: 'var(--color-gold-white)' }};
  ${({ $theme }) => $theme === 'dark' && { color: 'var(--color-white)', backgroundColor: 'var(--color-dark-grey)' }};
`

export const Masthead = styled.div`
  ${tw`text-center mb-12`};
`

export const Collections = styled.div`
  ${tw`grid grid-cols-1 gap-16`};
`
