import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  [data-slice-inset='true'] + [data-slice-inset='true'] {
    margin-top: -4rem !important;
  }

  @media (min-width: 62rem) {
    [data-slice-inset='true'] + [data-slice-inset='true'] {
      margin-top: -8rem !important;
    }
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

export const MissingSlice = styled.div`
  ${tw`m-2 p-2 border border-red-500`};
`

export const SliceWrapper = styled.div`
  ${tw`my-8 lg:my-16`};
`
