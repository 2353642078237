import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`py-insetY px-insetX ta-max-width-parent`};
  background-color: var(--color-gold-white);
`

export const HeaderAndBody = styled.div``

export const Main = styled.div`
  ${tw`grid md:grid-cols-2 gap-x-16 gap-y-8 p-8 md:p-16`};
  color: var(--color-gold-black);
  background-color: var(--color-white);
`

export const Form = styled.div`
  background-color: var(--color-white);
`
