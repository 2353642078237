import { rem } from 'polished'
import styled from 'styled-components'
import tw from 'twin.macro'

const variantStyle = ({ $variant }) => {
  switch ($variant) {
    case 'subdued':
      return {
        ...tw`py-16 grid gap-x-8 gap-y-8 items-center`,
        backgroundColor: 'var(--color-gold-white)',
      }
    case 'default':
      return {
        ...tw`grid gap-x-8 gap-y-8 items-center`,
      }
  }
}

export const Root = styled.div`
  ${tw`grid items-center px-insetX ta-max-width`};
  ${variantStyle};

  @media (min-width: 1024px) {
    grid-template-columns: auto 38%;
  }
`

export const Image = styled.figure`
  img {
    width: 100%;
  }
`

export const Main = styled.div`
  ${tw`px-10 lg:px-20 text-center`};
  ${({ $variant }) =>
    $variant === 'floating-image' && {
      ...tw`py-20`,
      backgroundColor: 'var(--color-dark-grey)',
      color: 'var(--color-white)',
    }};
  /* ${({ $variant }) => $variant === 'subdued' && { ...tw`text-center` }}; */

  @media (min-width: 1024px) {
    min-width: calc(234px + 10rem);
  }
`

export const Category = styled.div`
  text-transform: uppercase;
  line-height: 1.25rem;
  letter-spacing: 0.15em;
  font-size: ${rem(13)};
  color: var(--color-blue-grey);
`
