import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import RichText from 'components/RichText'

import { Inner, Root } from './RichTextSlice.style'

const RichTextSlice = ({ html }) => {
  if (isBlank(html)) return null

  return (
    <Root>
      <Inner>
        <RichText html={html} />
      </Inner>
    </Root>
  )
}

RichTextSlice.propTypes = {
  html: PropTypes.string,
}

RichTextSlice.defaultProps = {}

export default RichTextSlice
