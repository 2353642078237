// import PropTypes from 'prop-types'
import { useRecoilValueLoadable } from 'recoil'

import prismicArticleToArticleCardProps from 'converters/prismicArticleToArticleCardProps'

import { popularArticlesState } from 'state/selectors'

import ArticleCard from 'components/ArticleCard'
import Header from 'components/Header'
import Spacer from 'components/Spacer'

import { Column, Columns, Root } from './PopularArticlesSlice.style'

const PopularArticlesSlice = () => {
  const popularArticlesLoadable = useRecoilValueLoadable(popularArticlesState)
  const popularArticles = popularArticlesLoadable.state === 'hasValue' ? popularArticlesLoadable.contents : []

  // TODO exclude current article

  return (
    <Root>
      <Header>Most Popular Articles</Header>
      <Spacer y={8} />
      <Columns>
        {popularArticles
          ?.slice(0, 4)
          ?.map(prismicArticleToArticleCardProps)
          ?.map((props) => (
            <Column key={props.handle}>
              <ArticleCard {...props} />
            </Column>
          ))}
      </Columns>
    </Root>
  )
}

PopularArticlesSlice.propTypes = {}

PopularArticlesSlice.defaultProps = {}

export default PopularArticlesSlice
