import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

export const Main = styled.div`
  ${tw`flex flex-wrap -m-12`};
`

export const ItemsList = styled.div`
  ${tw`hidden md:block w-full md:w-1/4 p-12`};
`

export const Items = styled.div`
  ${tw`w-full md:w-3/4 p-12`};
`

export const SectionToggle = styled.button.attrs((props) => ({ ...props, buttonType: 'button' }))`
  color: var(--color-gold-on-gold-light-wcag);
`
