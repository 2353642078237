import styled from 'styled-components'
import tw from 'twin.macro'

const gridColumns = ({ $articlesLength }) => {
  const output = []

  if ($articlesLength >= 2) output.push(tw`sm:grid-cols-2`)
  if ($articlesLength >= 3) output.push(tw`md:grid-cols-3`)

  return output
}

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

export const Columns = styled.div`
  ${tw`grid grid-cols-1 gap-8`};
  ${gridColumns};
`

export const Column = styled.div``
