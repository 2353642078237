import PropTypes from 'prop-types'

import { isBlank } from 'utils'

import Header from 'components/Header'
import SubHeader from 'components/SubHeader'

import { DefaultLayout, InsetLayout } from './HeaderSlice.style'

const layouts = {
  default: DefaultLayout,
  inset: InsetLayout,
}

const texts = {
  header: Header,
  subheader: SubHeader,
}

const HeaderSlice = ({ text, variant, layout, align }) => {
  if (isBlank(text)) return null

  const Root = layouts[layout]
  const Text = texts[variant]

  return (
    <Root $align={align}>
      <Text html={text} />
    </Root>
  )
}

// style -> variant
//   Title -> header
//   Sub-title -> subheader
// design -> layout
//   Default -> default
//   Inset with Background -> inset

HeaderSlice.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['header', 'subheader']),
  layout: PropTypes.oneOf(['default', 'inset']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
}

HeaderSlice.defaultProps = {
  variant: 'header',
  layout: 'default',
  align: 'left',
}

export default HeaderSlice
