import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  /* ${tw`grid grid-cols-1 md:grid-cols-2 items-center`}; */
  ${tw`grid items-center`};
  background-color: var(--color-gold-white);

  ${({ $theme }) => $theme === 'light' && { backgroundColor: 'var(--color-white)' }};
  ${({ $theme }) => $theme === 'dark' && { backgroundColor: 'var(--color-darker-grey)' }};

  @media (min-width: 48rem) {
    /* grid-template-columns: ${({ $reverse }) => ($reverse ? 'repeat(2, 1fr)' : '')}; */
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 62rem) {
    grid-template-columns: ${({ $reverse }) =>
      $reverse ? 'minmax(0, 38%) minmax(0, 62%)' : 'minmax(0, 62%) minmax(0, 38%)'};
  }
`

export const Main = styled.div`
  ${tw`relative p-8 sm:p-12 text-center flex flex-col items-center justify-center`};
  align-self: normal;
  ${({ $reverse }) => $reverse && tw`order-first`};
  color: var(--color-gold-black);
  background-color: var(--color-gold-white);

  ${({ $theme }) => $theme === 'light' && { backgroundColor: 'var(--color-white)' }};
  ${({ $theme }) => $theme === 'dark' && { color: 'var(--color-white)', backgroundColor: 'var(--color-darker-grey)' }};
`
