import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  display: block;
`

export const ImageWrapper = styled.div`
  position: relative;
`

const LocationBadge = styled.div`
  ${tw`absolute right-0 bottom-0 mr-3`};
  transform: translateY(50%);
`

export const LocationBadgeX = styled(LocationBadge)`
  display: block;

  @media (min-width: 36rem) {
    display: none;
  }

  svg {
    width: 56px;
    height: 32px;

    @media (min-width: 36rem) {
      width: 67px;
      height: 38px;
    }
  }
`
export const LocationBadgeY = styled(LocationBadge)`
  display: none;

  @media (min-width: 36rem) {
    display: block;
  }

  svg {
    width: 32px;
    height: 56px;

    @media (min-width: 36rem) {
      width: 38px;
      height: 67px;
    }
  }
`

export const Title = styled.div`
  ${tw`pr-16`};
`
