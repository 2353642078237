import Image from 'next/image'
import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import { Quote, Root } from './QuoteSlice.style'

const QuoteSlice = ({ header, image, quote, caption }) => {
  return (
    <Root>
      {isPresent(header) && (
        <>
          <Header balance>{header}</Header>
          <Spacer y={12} />
        </>
      )}
      {isPresent(image?.url) && (
        <>
          <Image
            src={image.url}
            alt=""
            width={image.dimensions.width}
            height={image.dimensions.height}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Spacer y={24} />
        </>
      )}
      <Quote>
        <Text html={quote} font="ernestine" style="italic" size="2xl" prose />
      </Quote>
      <Spacer y={12} />
      <figcaption>
        <SubHeader>{caption}</SubHeader>
      </figcaption>
    </Root>
  )
}

QuoteSlice.propTypes = {
  header: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  quote: PropTypes.string,
  caption: PropTypes.string,
}

QuoteSlice.defaultProps = {}

export default QuoteSlice
