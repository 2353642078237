const articleAndProductProductToProductCardSmallProps = (product) => {
  const color = product.options?.color

  return {
    key: product._id,
    handle: product.handle,
    imageUrl: product.images[0],
    title: [product.title, color].filter(Boolean).join(' - '),
    price: product.price,
    compareAtPrice: product.compare_at_price,
    artisan: product.named_tags?.displayBrand || product.vendor,
  }
}

export default articleAndProductProductToProductCardSmallProps
