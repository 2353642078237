import PropTypes from 'prop-types'

import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Image, ImageWrapper, Main, Root } from './TeamMember.style'

const TeamMember = ({ photoUrl, name, biography }) => {
  return (
    <Root>
      <ImageWrapper>
        <Image src={photoUrl} alt={name} />
      </ImageWrapper>
      <Main>
        <Header size="sm">{name}</Header>
        <Spacer y={2} />
        <Text html={biography} />
      </Main>
    </Root>
  )
}

TeamMember.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string,
  biography: PropTypes.string,
}

TeamMember.defaultProps = {}

export default TeamMember
