import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

export const VideoWrapper = styled.div`
  ${tw`relative overflow-hidden max-w-full`};
  padding-bottom: 56.25%;
  height: 0;
  background-color: var(--color-black);

  iframe,
  object,
  embed {
    ${tw`absolute top-0 left-0 w-full h-full`}
  }
`

export const Title = styled.div`
  ${tw`mt-6 text-center`}
`
