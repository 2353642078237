import PropTypes from 'prop-types'
import { Fragment, useRef } from 'react'

import Accordion from 'components/Accordion'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import { Items, ItemsList, Main, Root, SectionToggle } from './FaqsSlice.style'

const FaqsSlice = ({ header, items }) => {
  const accordionRef = useRef(null)

  return (
    <Root>
      <Header>{header}</Header>
      <Spacer y={6} />
      <Main>
        <ItemsList>
          <SubHeader>Sections</SubHeader>
          <Spacer y={1} />
          {items.map(({ header }, index) => (
            <Fragment key={index}>
              <Spacer y={1} />
              <SectionToggle onClick={() => accordionRef.current.toggleItemAtIndex(index)}>
                <Text>{header}</Text>
              </SectionToggle>
            </Fragment>
          ))}
        </ItemsList>
        <Items>
          <Accordion ref={accordionRef} items={items} />
        </Items>
      </Main>
    </Root>
  )
}

FaqsSlice.propTypes = {
  header: PropTypes.string,
}

FaqsSlice.defaultProps = {}

export default FaqsSlice
