import { isPresent } from 'utils'

const collectionProductToProductCardProps = (collectionProduct) => {
  const color = collectionProduct.options?.color
  const imagesForColor = collectionProduct.images_by_color?.[color]
  const imageUrl = color ? imagesForColor?.[0] : collectionProduct.images?.[0]
  const altImageUrl = color ? imagesForColor?.[1] : collectionProduct.images?.[1]

  return {
    key: collectionProduct._id,
    handle: collectionProduct.handle,
    variantId: collectionProduct.variant_id,
    imageUrl: imageUrl || null,
    altImageUrl: altImageUrl || null,
    title: [collectionProduct.title, color].filter(Boolean).join(' - '),
    price: collectionProduct.price,
    compareAtPrice: collectionProduct.compare_at_price,
    sizes: collectionProduct.size_variants?.map(({ size, inventory_quantity, variant_id }) => ({
      size,
      available: inventory_quantity > 0,
      id: variant_id,
    })),
    artisan: collectionProduct.named_tags?.displayBrand || collectionProduct.vendor,
    isEssential: isPresent(collectionProduct.named_tags?.essentials),
  }
}

export default collectionProductToProductCardProps
