import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Category, Main, Root } from './FeaturedArticleSlice.style'

const baseUrl = '/journal/'

const loader = ({ src, width, quality }) => {
  return `${src}&w=${width}&q=${quality || 75}&ar=6:4&fit=crop`
}

const FeaturedArticleSlice = ({ variant, image, category, title, excerpt, buttonText, handle }) => {
  const buttonVariant = variant === 'subdued' ? 'text' : 'outlined'

  return (
    <Link href={`${baseUrl}${handle}`} passHref>
      <Root $variant={variant}>
        <Image
          loader={loader}
          width="600"
          height="400"
          src={image}
          alt={title}
          sizes="100vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
        <Main $variant={variant}>
          <Category>
            <Text font="proxima" tracking="wide" transform="uppercase" size="sm">
              {category}
            </Text>
          </Category>
          <Spacer y={2} />
          <Header balance>{title}</Header>
          <Spacer y={8} />
          <Text html={excerpt} balance />
          <Spacer y={8} />
          <Button as="span" variant={buttonVariant} text={buttonText} />
        </Main>
      </Root>
    </Link>
  )
}

FeaturedArticleSlice.propTypes = {
  variant: PropTypes.oneOf(['default', 'floating-image', 'subdued']),
  image: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  buttonText: PropTypes.string,
  handle: PropTypes.string,
}

FeaturedArticleSlice.defaultProps = {
  buttonText: 'Read more',
}

export default FeaturedArticleSlice
