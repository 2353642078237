import PropTypes from 'prop-types'

import Spacer from 'components/Spacer'
import SubHeader from 'components/SubHeader'
import Text from 'components/Text'

import { Introduction, Main, Root } from './NarrativeSlice.style'

const NarrativeSlice = ({ header, introduction, body }) => {
  return (
    <Root>
      <Introduction>
        <SubHeader>{header}</SubHeader>
        <Spacer y={2} />
        <Text html={introduction} font="freight" size="2xl" />
      </Introduction>
      <Main>
        <Text html={body} />
      </Main>
    </Root>
  )
}

NarrativeSlice.propTypes = {
  header: PropTypes.string,
  introduction: PropTypes.string,
  body: PropTypes.string,
}

NarrativeSlice.defaultProps = {}

export default NarrativeSlice
