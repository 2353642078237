// import PropTypes from 'prop-types'
import { Fragment } from 'react'

import PrismicPageHead from 'components/PrismicPageHead'

import { sliceComponents, sliceSchemaConverters } from './PrismicPage.helpers'
import { Root, SliceWrapper } from './PrismicPage.style'

const Slice = ({ slice }) => {
  if (!slice) return null

  const { slice_type } = slice

  const Component = sliceComponents?.[slice_type]
  const props = sliceSchemaConverters.find(({ type }) => type === slice_type)?.converter(slice) || slice

  if (props === null) return null

  // Horrible hack
  const inset = /inset/.test(JSON.stringify(props)) || slice_type === 'campaign_monitor'

  if (!Component) {
    console.error(`No component found for Prismic slice type: ${slice_type}`)

    return null
  }

  return (
    <SliceWrapper data-prismic-type={slice_type} data-slice-inset={inset}>
      <Component {...props} />
    </SliceWrapper>
  )
}

const PrismicPage = ({ document }) => {
  const body = document?.data?.body

  return (
    <>
      <PrismicPageHead document={document} />
      <Root>
        {body?.map((slice, index) => (
          <Slice key={index} slice={slice} />
        ))}
      </Root>
    </>
  )
}

PrismicPage.propTypes = {}

PrismicPage.defaultProps = {}

export default PrismicPage
