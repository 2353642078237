import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`flex sm:items-center`};
`

export const ImageWrapper = styled.div`
  ${tw`w-24 h-24 sm:w-40 sm:h-40 flex-none mr-6`};
`
export const Image = styled.img`
  ${tw`rounded-full block object-cover w-full h-full`};
`
export const Main = styled.div`
  ${tw`flex-grow`};
`
