import PropTypes from 'prop-types'
import { useRecoilValueLoadable } from 'recoil'

import { isPresent } from 'utils'

import { trunkShowsState } from 'state/selectors'

import Button from 'components/Button'
import Header from 'components/Header'
import RichText from 'components/RichText'
import Spacer from 'components/Spacer'
import TrunkShowCard from 'components/TrunkShowCard'

import { Column, Columns, Main, Root } from './TrunkShowsSlice.style'

const TrunkShowsSlice = ({ header, body, trunkShows: trunkShowsProp, method, buttonText, buttonLink }) => {
  const trunkShowsLoadable = useRecoilValueLoadable(trunkShowsState)
  const trunkShowsFromState = trunkShowsLoadable.state === 'hasValue' ? trunkShowsLoadable.contents : []
  const trunkShows = method === 'all' ? trunkShowsFromState.slice(0, 3) : trunkShowsProp

  return (
    <Root>
      <Main>
        <div>
          {isPresent(header) && (
            <>
              <Header>{header}</Header>
              <Spacer y={8} />
            </>
          )}
          {isPresent(body) && (
            <>
              <RichText html={body} prose />
            </>
          )}
        </div>
        {isPresent(buttonText) && isPresent(buttonLink) && (
          <>
            <Button variant="outlined" text={buttonText} as="a" href={buttonLink} />
          </>
        )}
      </Main>
      <Spacer y={8} />
      <Columns $trunkShowsLength={trunkShows?.length || 0}>
        {trunkShows?.map((trunkShow, index) => (
          <Column key={index}>
            <TrunkShowCard {...trunkShow} />
          </Column>
        ))}
      </Columns>
    </Root>
  )
}

TrunkShowsSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  trunkShows: PropTypes.array,
  method: PropTypes.oneOf(['all', 'manual']),
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

TrunkShowsSlice.defaultProps = {}

export default TrunkShowsSlice
