import NextImage from 'next/image'
import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-6`};
  ${({ $layout }) => ($layout === 'horizontal' ? tw`grid-cols-2 items-start` : tw`grid-flow-row`)};
`

export const Main = styled.div``

export const Image = styled(NextImage)``

export const Excerpt = styled.div`
  [class^='Text'] {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
