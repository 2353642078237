import styled from 'styled-components'
import tw from 'twin.macro'

export const SizesList = styled.ul`
  ${tw`absolute left-0 bottom-6 w-full flex flex-wrap justify-center m-0 p-0 opacity-0 z-20`};
  transform: translateY(20%);
  transition: opacity 0.2s, transform 0.2s;
`

export const AltImage = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full z-10 opacity-0 pointer-events-none`};
  transition: opacity 0.2s;
`

export const Root = styled.div`
  ${tw`relative`};

  &:hover ${SizesList} {
    ${tw`opacity-100`};
    transform: translateY(0%);
  }

  &:hover ${AltImage} {
    ${tw`opacity-100`};
  }
`

export const Main = styled.div`
  ${tw`flex flex-col items-center justify-center text-center`};
`

export const ImageWrapper = styled.div`
  ${tw`relative`};

  img {
    width: 100%;
  }
`

export const ImageContainer = styled.div`
  ${tw`block`};
`

export const BlurUp = styled.img`
  ${tw`absolute top-0 left-0 w-full h-full`};
  z-index: -1;
`

export const SizesListItem = styled.li`
  ${tw`px-2 list-none`};
  margin-top: -1px;
  margin-left: -1px;
  border: solid 1px var(--color-gold-light);
  color: var(--color-gold);
  background-color: var(--color-white);

  ${({ $available }) =>
    !$available && {
      background: 'linear-gradient(to top right, white calc(50% - 1px), var(--color-grey-gold), white calc(50% + 1px))',
      color: 'var(--color-grey-gold)',
    }};

  &:hover {
    ${({ $available }) => $available && tw`underline`};
  }
`

export const CompareAtPrice = styled.div`
  color: var(--color-gold);
`

export const EssentialTag = styled.div`
  ${tw`inline-flex items-center absolute top-0 left-0 z-20 pl-2 pr-4 py-1 pointer-events-none`}
  color: var(--color-white);
  background-color: var(--color-gold);
  clip-path: polygon(0% 0%, 100% 0, 90% 50%, 100% 100%, 0% 100%);
`
