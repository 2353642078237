import styled from 'styled-components'
import tw from 'twin.macro'

const gridColumns = ({ $productsLength }) => {
  const output = []

  if ($productsLength >= 2) output.push(tw`grid-cols-2`)
  if ($productsLength >= 3) output.push(tw`md:grid-cols-3`)

  return output
}

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

export const Columns = styled.div`
  ${tw`grid grid-cols-1 gap-8`};
  ${gridColumns};
`

export const Column = styled.div`
  &:nth-child(n + 3) {
    ${tw`hidden md:block`};
  }
`
