import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Button from 'components/Button'
import Header from 'components/Header'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Content, Root } from './BannerSlice.style'

const BannerSlice = ({ backgroundImageUrl, header, body, buttonText, buttonLink, buttonVariant, align }) => {
  return (
    <Root $backgroundImageUrl={backgroundImageUrl}>
      <Content $align={align}>
        {isPresent(header) && (
          <>
            <Header balance>{header}</Header>
            <Spacer y={4} />
          </>
        )}
        {isPresent(body) && (
          <>
            <Text html={body} prose />
            <Spacer y={8} />
          </>
        )}
        {isPresent(buttonText) && isPresent(buttonLink) && (
          <Button variant={buttonVariant} text={buttonText} as="a" href={buttonLink} />
        )}
      </Content>
    </Root>
  )
}

BannerSlice.propTypes = {
  backgroundImageUrl: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonVariant: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
}

BannerSlice.defaultProps = {}

export default BannerSlice
