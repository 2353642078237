import styled from 'styled-components'
import tw from 'twin.macro'

const gridColumns = ({ $articlesLength }) => {
  if ($articlesLength === 1) {
    return tw`grid-cols-1`
  } else if ($articlesLength === 2) {
    return tw`grid-cols-1 lg:grid-cols-2`
  } else if ($articlesLength >= 3) {
    return tw`grid-cols-1 lg:grid-cols-2 max:grid-cols-3`
  }
}

export const Root = styled.div`
  ${tw`pb-8 px-insetX`};
`

export const Columns = styled.div`
  ${tw`grid gap-x-16 gap-y-8 overflow-hidden`};
  ${({ $articlesLength }) => $articlesLength === 1 && tw`w-1/2 mx-auto`};
  ${gridColumns};
`

export const Column = styled.div`
  ${tw`w-full relative`};

  &:before {
    content: '';
    ${tw`absolute top-0 left-0 h-full w-px -ml-8`};
    background-color: var(--color-grey);
  }
`
