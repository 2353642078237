import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`grid gap-8 px-insetX items-center ta-max-width`};
  grid-template-areas:
    'main'
    'image';

  @media (min-width: 768px) {
    grid-template-areas: 'main image';
    grid-template-columns: auto ${({ $imageWidth }) => ($imageWidth === '1/3' ? '33.33%' : '50%')};
  }
`

export const Main = styled.div`
  grid-area: main;
`

export const Image = styled.div`
  grid-area: image;
`
