import styled from 'styled-components'
import tw from 'twin.macro'

export const Root = styled.div`
  ${tw`px-insetX ta-max-width`};
`

const columns = ({ $imageAlignment, $maxColumnCount }) => {
  if ($imageAlignment === 'left') return tw`grid-cols-1 lg:grid-cols-2`

  if ($maxColumnCount === 2) return tw`sm:grid-cols-2`

  return tw`sm:grid-cols-2 md:grid-cols-3`
}

export const Columns = styled.div`
  ${tw`grid gap-8`};
  ${columns};
`

export const Column = styled.div`
  ${tw`w-full grid auto-rows-min gap-x-8 gap-y-4`};
  ${({ $imageAlignment }) => ($imageAlignment === 'left' ? tw`grid-cols-2` : tw`grid-cols-1`)};
  ${({ $focusFirst, $index }) => $focusFirst && $index === 0 && tw`col-span-2 md:row-span-2`};
`

export const ImageWrapper = styled.div`
  ${tw`block`};
`

export const Image = styled.img`
  ${tw`w-full`};
`

export const Main = styled.div`
  ${tw`w-full only:col-span-2 grid auto-rows-min gap-y-4`};
`
