import PropTypes from 'prop-types'

import { isPresent } from 'utils'

import Header from 'components/Header'
import ProductCard from 'components/ProductCard'
import Spacer from 'components/Spacer'
import Text from 'components/Text'

import { Column, Columns, Root } from './ProductsSlice.style'

const ProductsSlice = ({ header, body, products, ProductComponent }) => {
  return (
    <Root>
      {isPresent(header) && (
        <>
          <Header>{header}</Header>
          <Spacer y={8} />
          <Text html={body} />
        </>
      )}
      <Columns $productsLength={products?.length || 0}>
        {products?.map((product, index) => (
          <Column key={index}>
            <ProductComponent {...product} />
          </Column>
        ))}
      </Columns>
    </Root>
  )
}

ProductsSlice.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  products: PropTypes.array,
}

ProductsSlice.defaultProps = {
  ProductComponent: ProductCard,
}

export default ProductsSlice
